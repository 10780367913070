html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ebeded;
}

#root {
  height: 100%;
  min-height: 100vh;
  background-color: #ebeded;
}
