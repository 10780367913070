.tippy-popper {
  .tippy-tooltip {
    padding: 0;
    border-radius: 5px;
    background-color: #071a24;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);

    .tippy-tooltip-content {
      padding: 4px 8px;
      border-radius: 5px;
      text-align: left;
      color: #fff;
      font-weight: 500;
      font-size: 12px;
      line-height: 17px;
    }
  }

  &[x-placement^='top'] [x-arrow] {
    border-top-color: #071a24;
  }

  &[x-placement^='right'] [x-arrow] {
    border-right-color: #071a24;
  }

  &[x-placement^='bottom'] [x-arrow] {
    border-bottom-color: #071a24;
  }

  &[x-placement^='left'] [x-arrow] {
    border-left-color: #071a24;
  }
}

.tippy-wrapper-classname {
  display: inline-block !important;
}
